import { $authHost, $host } from '.'

export const createDevice = async type => {
	try {
		const { data } = await $authHost.post('/api/type', type)
		return data
	} catch (e) {
		console.error(e)
		throw e
	}
}
export const fetchDevises = async params => {
	try {
		const { data } = await $host.get('/api/products', {
			params: params,
		})
		return data
	} catch (e) {
		console.error(e)
		throw e
	}
}
export const fetchOneProduct = async id => {
	try {
		const { data } = await $host.get(`/api/products/${id}`)
		return data
	} catch (e) {
		console.error(e)
		throw e
	}
}
