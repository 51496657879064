import {
	fetchCategoriesTypes,
	fetchGenerations,
	fetchModels,
} from '../../../../http/carAndDetailSelectorAPI'
import { searchProducts } from '../../ShopDeviceList/SearchUtils'

export function setMarks(selectedTypeValue, device, search) {
	if (selectedTypeValue) {
		search.setStamp(selectedTypeValue)
		search.setGeneration('')
		search.setModel('')

		device.setBrands([])
		device.setGenerations([])
		fetchModels(selectedTypeValue).then(data => {
			device.setBrands(data)
		})
		searchProducts(search, device)
	}
}

export function setBrands(selectedTypeValue, device, search) {
	if (selectedTypeValue) {
		search.setGeneration('')
		search.setModel(selectedTypeValue)
		fetchGenerations(search.stamp, selectedTypeValue).then(data => device.setGenerations(data))

		searchProducts(search, device)
	}
}

export function setGeneration(selectedTypeValue, search, device, resetDropdown) {
	if (selectedTypeValue) {
		search.setGeneration(selectedTypeValue)
		if (!resetDropdown) {
			searchProducts(search, device)
		} else {
			searchProducts(search, device).then(() => {
				search.plusKeyCounter()
			})
		}
	}
}

export async function setCategories(selectedTypeValue, search, device) {
	if (selectedTypeValue) {
		search.setType('')
		searchProducts(search, device)

		search.setCategory(selectedTypeValue)
		fetchCategoriesTypes(selectedTypeValue).then(data => device.setCategoriesTypes(data))
	}
}

export function setCategoriesTypes(selectedTypeValue, search, device, resetDropdown) {
	if (selectedTypeValue) {
		search.setType(selectedTypeValue)
		if (!resetDropdown) {
			searchProducts(search, device)
		} else {
			searchProducts(search, device).then(() => {
				search.plusKeyCounter()
			})
		}
	}
}
