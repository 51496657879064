import Admin from './app/Admin/page'
import { default as Auth } from './app/Auth/page'
import Basket from './app/Basket/page'
import CreateAnAd from './app/CreateAnAd/page'
import DevicePage from './app/DevicePage/page'
import Main from './app/Main/page'
import Shop from './app/Shop/page'
import UserProfileAds from './app/UserProfile/page'
import Chat from './app/TestChat/page'
import {
	ADMIN_PANEL_ROUTE,
	ADMIN_ROUTE,
	BASKET_ROUTE,
	CREATE_AN_AD,
	DEVICE_ROUTE,
	LOGIN_ROUTE,
	MAIN_ROUTE,
	REGISTRATION_ROUTE,
	SHOP_ROUTE,
	USER_PROFILE_ADS,
	CHAT_ROUTE,
} from './utils/consts'

export const authRoutes = [
	{
		path: BASKET_ROUTE,
		Component: Basket,
	},
]

export const publicRoutes = [
	{
		path: MAIN_ROUTE,
		Component: Main,
	},
	{
		path: ADMIN_ROUTE,
		Component: Auth,
	},
	{
		path: ADMIN_PANEL_ROUTE,
		Component: Admin,
	},
	{
		path: LOGIN_ROUTE,
		Component: Auth,
	},
	{
		path: REGISTRATION_ROUTE,
		Component: Auth,
	},
	{
		path: CREATE_AN_AD,
		Component: CreateAnAd,
	},
	{
		path: SHOP_ROUTE,
		Component: Shop,
	},
	{
		path: USER_PROFILE_ADS,
		Component: UserProfileAds,
	},
	{
		path: DEVICE_ROUTE + '/:id',
		Component: DevicePage,
	},
	{
		path: CHAT_ROUTE + '/:dest_id',
		Component: Chat,
	},
]
