import useWebSocket, { ReadyState } from "react-use-websocket";
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { getRoom, profileInfo, createMessage } from "../../../http/userAPI";
import { FileIcon, defaultStyles } from 'react-file-icon';

const useChatScroll = (dep) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);

  return ref;
};

const Chat = () => {
  const websocket_host = process.env.REACT_APP_API_URL.replace("https://", "wss://").replace("http://", "ws://");
  const [messages, setMessages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocket_host + "chat/connect",
    {
      share: false,
      shouldReconnect: () => true,
    },
  );
  const { dest_id } = useParams();
  const [destName, setDestName] = useState('');
  const textareaRef = useRef(null);
  const chatRef = useChatScroll(messages);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (readyState === ReadyState.CONNECTING) {
      console.log("Connection opened");
    }
    if (readyState === ReadyState.CLOSED) {
      console.log("Connection closed");
    }
  }, [readyState]);

  useEffect(() => {
    let res = JSON.parse(lastJsonMessage);
    if (res?.status_code.toString().charAt(0) === '2') {
      setMessages(prevMessages => [...prevMessages, res.detail]);
    } else {
      console.log(res?.detail);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    profileInfo(dest_id).then(res => {
      setDestName(res.data.name);
    });
  }, [dest_id]);

  useEffect(() => {
    getRoom(dest_id).then(res => {
      setMessages(res.data);
    });
  }, [dest_id]);

  const handleTextareaChange = e => {
    setNewMessage(e.target.value);
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = Math.min(textarea.scrollHeight, 120) + 'px'; // Limit the height to 120px
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      let message = {text: newMessage, dest_id: dest_id, attachments: attachments};
      sendJsonMessage(JSON.stringify(message));
      setMessages(prevMessages => [...prevMessages, message]);
      setNewMessage('');
      setAttachments([]);

      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
    } catch (e) {
      console.error(e);
    }
  };

  const handleAttachMedia = (event) => {
    if (event.target.files) {
      for (const file of Array.from(event.target.files)) {
        let reader = new FileReader();
        reader.onloadend = () => {
          let newAttachment = {
            document: reader.result,
            type: file.type.split("/")[0],
            extension: file.name.split(".")[1],
            filename: file.name
          };
          console.log(file.name)
          setAttachments(prevAttachments => [...prevAttachments, newAttachment])
        };
        reader.readAsDataURL(file);
      }
    }
    event.target.value = '';
  };

  return (
    <div className='flex flex-col p-6 rounded-xl border border-gray-900/10'>
      <div className='flex items-center p-3 bg-accent m-text rounded-xl'>
        <p className='text-lg'>{destName}</p>
      </div>
      <div ref={chatRef} className='overflow-y-auto p-6 mt-2 mb-2 h-80'>
        {messages.length === 0 ? (
          <div className='m-text text-center'>Тут пока пусто...</div>
        ) : (
          messages.map((msg, index) => (
            <div key={index} className={'my-2 p-2 bg-grey-90 rounded ' + (msg.dest_id == dest_id ? '' : 'to-right')}>
              <p className="whitespace-pre-wrap">{msg.text}</p>
              {msg.attachments && msg.attachments.length > 0 && (
                <div className="mt-2">
                  {msg.attachments.map((attachment, attachmentIndex) => (
                    <div key={attachmentIndex} className="flex items-center gap-2">
                      {attachment.type === "image" &&
                          <img src={attachment.document} />
                      }
                      {attachment.type === "video" &&
                          <video>
                            <source src={attachment.document}/>
                          </video>
                      }
                      {attachment.type === "audio" &&
                          <audio>
                            <source src={attachment.document}/>
                          </audio>
                      }
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </div>
      <div className='flex items-end p-6 rounded-xl bg-white'>
        <div>
          <div className='flex justify-center'>
            <label htmlFor="attachments" style={{ cursor: "pointer" }}>
              <img className='scale-75' src="/images/paperclip.svg" alt="Select media" />
            </label>
            <input type="file" accept="image/png, image/jpg, audio/*, video/*" id="attachments" ref={fileInputRef} onChange={handleAttachMedia} hidden />
            {attachments.length > 0 && (
              <div className="flex gap-2 mt-2" style={{maxHeight: "42px", marginRight: "10px"}}>
                {attachments.map((attachment, index) => (
                    <div style={{maxWidth: "30px"}}>
                      <FileIcon key={index} extension={attachment.extension} {...defaultStyles[attachment.extension]} />
                    </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <textarea
          ref={textareaRef}
          value={newMessage}
          onChange={handleTextareaChange}
          className='flex-1 p-2 border border-gray-300 rounded mr-2 outline-none resize-none max-h-24 overflow-y-auto'
          placeholder='Введите сообщение...'
          rows={1}
        />
        <button onClick={handleSendMessage} className='button button-accent-hov'>
          Отправить
        </button>
      </div>
    </div>
  );
};

export default Chat;