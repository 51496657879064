import { PhotoIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'

const FileUpload = ({ onFileSelect }) => {
	const [uploading, setUploading] = useState(false)
	const [message, setMessage] = useState('')

	const handleUpload = async files => {
		if (!files || files.length === 0) {
			setMessage('No file selected')
			return
		}

		try {
			setUploading(true)
			setMessage('')

			const file = files[0]
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onloadend = () => {
				const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
				onFileSelect(base64String)
				setMessage('File selected successfully')
			}
		} catch (error) {
			console.error('Ошибка выбора файла:', error)
			setMessage('Failed to select file')
		} finally {
			setUploading(false)
		}
	}

	return (
		<div className='col-span-full'>
			<div className='mt-2 cursor-pointer flex relative justify-center rounded-lg group border border-gray-900/10 px-6 py-10 hover:border-accent/30'>
				<input
					type='file'
					id='file-upload'
					name='file-upload'
					className='opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer'
					onChange={e => handleUpload(e.target.files)}
					disabled={uploading}
				/>
				<div className='text-center'>
					<PhotoIcon
						className='mx-auto h-12 w-12 text-gray-300 group-hover:text-accent'
						aria-hidden='true'
					/>
					<div className='mt-4 flex text-sm leading-6'>
						<label
							htmlFor='file-upload'
							className='rounded-md bg-gray-900/10 focus-within:outline-none focus-within:ring-2'>
							<span className='p-2'>{uploading ? 'Uploading...' : 'Загрузить изображение'}</span>
						</label>
						<p className='pl-1'>или перетащите сюда</p>
					</div>
					<p className='text-xs leading-5'>PNG, JPG, GIF до 10 МБ</p>
				</div>
			</div>
			{message && <p>{message}</p>}
		</div>
	)
}

export default FileUpload
