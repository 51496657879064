import { Dialog, Transition } from '@headlessui/react'
import { BookA, BookImageIcon, FileEditIcon, LucideBookPlus, LucideFileText } from 'lucide-react'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useRef, useState } from 'react'
import { Context } from '../..'
import Dropdown from '../ui/Dropdown/Dropdowns'
import Field from '../ui/Field/Field'

const CreateDevice = observer(({ show, onHide }) => {
	const cancelButtonRef = useRef(null)
	const { device } = useContext(Context)
	const [info, setInfo] = useState([])

	const addInfo = () => {
		setInfo([...info, { title: '', description: '', number: Date.now() }])
	}

	const removeInfo = number => {
		setInfo(info.filter(i => i.number !== number))
	}

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={onHide}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
							<Dialog.Panel className='relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
								<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
									<div className='sm:flex sm:items-start'>
										<div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
											<Dialog.Title as='h3' className='text-lg font-bold leading-6 text-gray-900'>
												Создать девайс
											</Dialog.Title>
											<div className='mt-4'>
												<form className='w-full'>
													<Dropdown
														items={device.types}
														name={'Выберите тип'}
														className='w-full'
														fullWidth={true}
													/>
													<div className='mb-7'></div>
													<Dropdown
														items={device.brands}
														name={'Выберите бренд'}
														className='w-full'
														fullWidth={true}
													/>
													<Field
														required={true}
														placeholder='Введите название Устройства'
														className='mb-7 mt-7 bg-grey p-3 w-full rounded-2xl'
														type='email'
														Icon={BookA}
														full='w-full'
													/>
													<Field
														required={true}
														placeholder='Введите стоимость устройства'
														className='mb-7 bg-grey p-3 w-full rounded-2xl'
														type='number'
														Icon={LucideBookPlus}
														full='w-full'
													/>
													<Field
														required={true}
														placeholder='Введите стоимость устройства'
														className='mb-7 bg-grey p-3 w-full rounded-2xl'
														type='file'
														Icon={BookImageIcon}
														full='w-full'
													/>

													{info.map((i, index) => (
														<div
															key={i.number}
															className='d-flex mb-7 justify-between p-3 bg-grey rounded-2xl'>
															<div className='flex justify-between items-center p-3 mb-2'>
																<h3 className='font-semibold'>Свойство №{index + 1}</h3>
																<button
																	type='button'
																	className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
																	onClick={() => removeInfo(i.number)}
																	ref={cancelButtonRef}>
																	Удалить
																</button>
															</div>
															<Field
																required={true}
																placeholder='Введите название'
																className='mb-7 w-full bg-white p-3 rounded-2xl'
																type='name'
																Icon={FileEditIcon}
																full='w-full'
															/>

															<Field
																required={true}
																placeholder='Введите описание'
																className='w-full mb-7 bg-white p-3 rounded-2xl'
																type='name'
																Icon={LucideFileText}
																full='w-full'
															/>
														</div>
													))}
												</form>
											</div>
										</div>
									</div>
								</div>
								<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
									<button
										type='button'
										className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
										onClick={() => onHide(false)}>
										Создать
									</button>

									<button
										type='button'
										className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
										onClick={addInfo}>
										Добавить информацию
									</button>

									<button
										type='button'
										className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
										onClick={() => onHide(false)}
										ref={cancelButtonRef}>
										Закрыть
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
})

export default CreateDevice
