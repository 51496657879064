import { AtSign, AtomIcon, Home, KeyRound, Phone } from 'lucide-react'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../..'
import { LOGIN_ROUTE, REGISTRATION_ROUTE } from '../../../utils/consts'
import Field from '../../ui/Field/Field'
import AccessAlert from '../../ui/modal/AccessAlert/AccessAlert'
import ErrorAlert from '../../ui/modal/ErrorAlert/ErrorAlert'
import { handleAuth } from './AuthUtils'
import PhoneVerification from '../../ui/modal/PhoneVerification/PhoneVerification'
import { sendSms } from '../../../http/userAPI'

const AuthPage = observer(() => {
	const { user } = useContext(Context)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [accessAlertShow, setAccessAlertShow] = useState(false)
	const [jwtToken, setJwtToken] = useState('')
	const location = useLocation()
	const isLogin = location.pathname === LOGIN_ROUTE
	// const isLocationAdmin = location.pathname === ADMIN_ROUTE
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [name, setName] = useState('')
	const [city, setCity] = useState('')
	const [phone, setPhone] = useState('')
	const [confirmedPhone, setConfirmedPhone] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')
	const [verificationShow, setVerificationShow] = useState(false)
	const [verificationId, setVerificationId] = useState('')

	const navigate = useNavigate()

	useEffect(() => {
		if (user.isAuth) {
			navigate('/')
		}
	}, [navigate, user.isAuth])

	const click = async e => {
		e.preventDefault()
		await loginOrRegister(jwtToken)
	}

	const loginOrRegister = async (token) => {
		setJwtToken(token)
		setConfirmedPhone(phone)
		await handleAuth(
			email,
			password,
			name,
			city,
			token,
			isLogin,
			user,
			setAlertMessage,
			setErrorStatus,
			setAlertTitle,
			setErrorAlertShow,
			setAccessAlertShow,
			navigate,
		)

	}

	const smsVerification = async e =>
	{
		e.preventDefault()
		if (jwtToken && confirmedPhone === phone) {
			return await loginOrRegister(jwtToken);
		}
		try{
			const response = await sendSms(phone.replace(/\s/g, ''))
			setVerificationId(response.data.verification_id)
			setVerificationShow(true)
		}
		catch(error){
			console.error(error)
			setAlertMessage(error.response)
			setErrorStatus(error.response?.status)
			setAlertTitle("Ошибка отправки смс")
			setErrorAlertShow(true)
		}
	}

	return (
		<div className='flex justify-center'>
			<form className='border border-border p-layout p-10 pb-10'>
				{!isLogin ? (
					<h1 className='text-center mb-10'>Регистрация</h1>
				) : (
					<h1 className='text-center mb-10'>Войти</h1>
				)}
				<div className='flex flex-col justify-center'>
					<Field
						required={true}
						placeholder='Ввести email'
						className='mb-7 bg-grey p-3 rounded-2xl w-full'
						type='email'
						Icon={AtSign}
						value={email}
						onChange={e => setEmail(e.target.value)}
						full='w-full'
					/>
					{!isLogin ? (
						<>
							<Field
								required={true}
								placeholder='Ввести имя'
								className='mb-7 bg-grey p-3 rounded-2xl w-full'
								type='name'
								Icon={AtomIcon}
								value={name}
								onChange={e => setName(e.target.value)}
								full='w-full'
							/>
							<Field
								required={true}
								placeholder='Ваш город'
								className='mb-7 bg-grey p-3 rounded-2xl w-full'
								type='city'
								Icon={Home}
								value={city}
								onChange={e => setCity(e.target.value)}
								full='w-full'
							/>
							<Field
								required={true}
								placeholder='Ваш номер телефона'
								className='mb-7 bg-grey p-3 rounded-2xl w-full'
								type='tel'
								Icon={Phone}
								value={phone}
								onChange={e => setPhone(e.target.value)}
								full='w-full'
							/>
						</>
					) : (
						''
					)}
					<Field
						required={true}
						placeholder='Ввести пароль'
						error={{ message: '', type: 'min' }}
						className='mb-7 bg-grey p-3 rounded-2xl w-80 flex'
						type='password'
						Icon={KeyRound}
						value={password}
						onChange={e => setPassword(e.target.value)}
						isLogin={isLogin}
					/>
					{!isLogin ? (
						<div className='mb-12 text-center'>
							Есть аккаунт?{' '}
							<NavLink className='link-accent' to={LOGIN_ROUTE}>
								Войти!
							</NavLink>
						</div>
					) : (
						<div className='mb-12 text-center'>
							Нет аккаунта?{' '}
							<NavLink className='link-accent' to={REGISTRATION_ROUTE}>
								Зарегистрируйся!
							</NavLink>
						</div>
					)}
					{!isLogin ? (
						<div className='text-center'>
							<button className='accent button button-accent' onClick={smsVerification}>
								Зарегистрироваться
							</button>
						</div>
					) : (
						<div className='text-center'>
							<button className='accent button button-accent' onClick={click}>
								Войти
							</button>
						</div>
					)}
				</div>
			</form>

			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				message={alertMessage}
				isLogin={isLogin}
			/>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
			/>

			<PhoneVerification 
			open={verificationShow}
			setOpen={setVerificationShow}
			verificationId={verificationId}
			handleRegister={loginOrRegister}
			/>
		</div>
	)
})

export default AuthPage
