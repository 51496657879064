import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from '../../..'

const CreateAnAdPage = observer(() => {
	const { dataSectionForAnAdPage } = useContext(Context)
	const activeDataSection = dataSectionForAnAdPage.dataSections.find(
		section => section.id === dataSectionForAnAdPage.activeDataSection,
	)
	return <div className='container'>{activeDataSection.component}</div>
})

export default CreateAnAdPage
