import { ArrowUpRight } from 'lucide-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './PlaceAnAd.module.scss'

const PlaceAnAd = ({ type, icon, headerTitle, underTitle, link = false, bgColor = false }) => {
	return (
		<NavLink className={`w-full ${styles.wrap__linker}`} to={link}>
			<div className={`${styles.wrap} ${bgColor ? styles.grey : styles.accent} w-full`}>
				<div className={styles.wrap__up}>
					<div className={styles.wrap__up__icon__wrap}>{icon}</div>
					<div className={styles.wrap__up__title}>{headerTitle}</div>
				</div>
				<div className={`${styles.wrap__down} flex`}>
					{underTitle}
					<div className={styles.arrow}>{!bgColor ? <ArrowUpRight /> : ''}</div>
				</div>
			</div>
		</NavLink>
	)
}

export default PlaceAnAd
