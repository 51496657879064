import { observer } from 'mobx-react-lite'
import React from 'react'
import CreateAnAdPage from '../../components/screens/createAnAd/CreateAnAdPage'

const CreateAnAd = observer(() => {
	return (
		<div>
			<CreateAnAdPage />
		</div>
	)
})

export default CreateAnAd
