import React from 'react'
import AdminPage from '../../components/screens/admin/AdminPage'

const Admin = () => {
	return (
		<div>
			<AdminPage />
		</div>
	)
}

export default Admin
