import React from 'react'
import AuthPage from '../../components/screens/auth/Auth'

const LoginPage = () => {
	return (
		<div className='container'>
			<AuthPage />
		</div>
	)
}

export default LoginPage
