import axios from 'axios'

const $host = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { 'Content-Type': 'application/json' },
})

const $authHost = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { 'Content-Type': 'application/json' },
})

const authInterceptor = config => {
	return config
}

$authHost.interceptors.request.use(authInterceptor)

export { $authHost, $host }
