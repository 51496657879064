import React from 'react'
import Navbar from '../ui/Navbar/Navbar'
import styles from './Layout.module.scss'

export default function LayoutClient({ children }) {
	return (
		<main className={styles.layout}>
			<Navbar />
			<section>{children}</section>
		</main>
	)
}
