import { login, registration} from '../../../http/userAPI'

export const handleAuth = async (
	email,
	password,
	name,
	city,
	jwtToken,
	isLogin,
	user,
	setAlertMessage,
	setErrorStatus,
	setAlertTitle,
	setErrorAlertShow,
	setAccessAlertShow,
	navigate,
) => {
	try {
		if (isLogin) {
			await login(email, password)
			setAlertTitle('Вы успешно авторизировались!')
		} else {
			await registration(email, password, name, city, jwtToken)
			await login(email, password)
			setAlertTitle('Вы успешно зарегистрировались!')
		}

		setAccessAlertShow(true)
		setTimeout(() => {
			user.setIsAuth(true)
			navigate('/')
		}, 1500)
	} catch (error) {
		console.log(error)

		setAlertMessage(error.response)
		setErrorStatus(error.response?.status)
		setAlertTitle(isLogin ? 'Ошибка авторизации' : 'Ошибка регистрации')
		setErrorAlertShow(true)
	}
}