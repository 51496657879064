import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from '../../../..'
import Dropdown from '../../Dropdown/Dropdowns'
import {
	setBrands,
	setCategories,
	setCategoriesTypes,
	setGeneration,
	setMarks,
} from './NavbarSelectedUtils'

const NavbarSelected = observer(() => {
	const { device, search } = useContext(Context)
	return (
		<div>
			<div className='flex flex-col xl:flex-row gap-3 gap-x-12'>
				<div className='flex flex-col xl:flex-row gap-3 gap-x-1'>
					<Dropdown
						key={`generationMark-${search.keyCounter}`}
						items={device.types}
						name={'Марка'}
						className={`w-full ${
							device.types?.length > 0 ? '' : 'pointer-events-none opacity-50 w-full'
						}`}
						fullWidth={true}
						isPageShop={true}
						type={false}
						onChangeCallback={selectedTypeValue => {
							setMarks(selectedTypeValue, device, search)
						}}
					/>
					<Dropdown
						key={`brandsDropdown-${search.stamp + search.keyCounter}`}
						items={device.brands}
						name={'Модель'}
						className={`w-full ${
							device.brands?.length > 0 ? '' : 'pointer-events-none opacity-50'
						}`}
						fullWidth={true}
						isPageShop={true}
						onChangeCallback={selectedTypeValue => {
							setBrands(selectedTypeValue, device, search)
						}}
					/>
					<Dropdown
						key={`generationDropdown-${search.stamp + search.model + search.keyCounter}`}
						items={device.generations}
						name={'Поколение'}
						className={`w-full ${
							device.generations?.length > 0 ? '' : 'pointer-events-none opacity-50'
						}`}
						fullWidth={true}
						isPageShop={true}
						onChangeCallback={selectedTypeValue => {
							setGeneration(selectedTypeValue, search, device)
						}}
					/>
				</div>

				<div className='flex gap-3 gap-x-1 flex-col xl:flex-row'>
					<Dropdown
						key={`generationCategories-${search.keyCounter}`}
						items={device.categories}
						name={'Категория'}
						className={`w-full ${
							device.categories?.length > 0 ? '' : 'pointer-events-none opacity-50'
						}`}
						fullWidth={true}
						isPageShop={true}
						onChangeCallback={selectedTypeValue => {
							setCategories(selectedTypeValue, search, device)
						}}
					/>
					<Dropdown
						key={`brandsCategoriesTypes-${search.category + search.keyCounter}`}
						items={device.categoriesTypes}
						name={'Тип'}
						className={`w-full ${
							device.categoriesTypes?.length > 0 ? '' : 'pointer-events-none opacity-50'
						}`}
						fullWidth={true}
						isPageShop={true}
						onChangeCallback={selectedTypeValue => {
							setCategoriesTypes(selectedTypeValue, search, device)
						}}
					/>
				</div>
			</div>
		</div>
	)
})

export default NavbarSelected
