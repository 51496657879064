import { Dialog, Transition } from '@headlessui/react'
import { BookTypeIcon } from 'lucide-react'
import { Fragment, useRef, useState } from 'react'
import { createCarsBrands } from '../../http/carAndDetailSelectorAPI'
import Field from '../ui/Field/Field'

export default function CreateType({ show, onHide }) {
	const cancelButtonRef = useRef(null)
	const [nameBrand, setNameBrand] = useState('')

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={onHide}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
							<Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
								<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
									<div className='sm:flex sm:items-start'>
										<div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
											<Dialog.Title as='h3' className='text-lg font-bold leading-6 text-gray-900'>
												Создать тип
											</Dialog.Title>
											<div className='mt-4'>
												<form className='w-full'>
													<Field
														required={true}
														placeholder='Введите название типа'
														className='mb-7 bg-grey p-3 w-full rounded-2xl'
														type='email'
														Icon={BookTypeIcon}
														full='w-full'
														onChange={setNameBrand}
														value={nameBrand}
													/>
												</form>
											</div>
										</div>
									</div>
								</div>
								<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
									<button
										type='button'
										className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
										onClick={() => createCarsBrands(nameBrand)}>
										Создать
									</button>
									<button
										type='button'
										className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
										onClick={() => onHide(false)}
										ref={cancelButtonRef}>
										Закрыть
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
