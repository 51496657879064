import React from 'react'
import MainPage from '../../components/screens/main/Main'

const Main = () => {
	return (
		<div className='container'>
			<MainPage />
		</div>
	)
}

export default Main
