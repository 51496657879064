import React from 'react'
import Shop from '../../components/screens/shop/Shop'

const ShopPage = () => {
	return (
		<div className='container'>
			<Shop />
		</div>
	)
}

export default ShopPage
