import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import AppRouter from './components/AppRouter/AppRouter.js'
import LayoutClient from './components/Layout/Layout.js'
const App = () => {
	return (
		<BrowserRouter>
			<LayoutClient>
				<AppRouter />
			</LayoutClient>
		</BrowserRouter>
	)
}

export default App
