import cn from 'clsx'
import React, { forwardRef, useState } from 'react'
import styles from './Field.module.scss'
import { onPhoneInput, onPhoneKeyDown, onPhonePaste } from './PhoneInputUtils'

const Field = forwardRef(
	({ error, style, Icon, className, type, value, full = '', isLogin, ...rest }, ref) => {
		const [passwordLength, setPasswordLength] = useState(value?.length)

		const handlePasswordInput = e => {
			setPasswordLength(e.target?.value?.length)
		}

		const handleInput = e => {
			switch (type) {
				case 'tel':
					onPhoneInput(e)
					break
				case 'password':
					handlePasswordInput(e)
					break
				default:
					break
			}
		}

		const handleKeyDown = e => {
			if (type === 'tel') {
				onPhoneKeyDown(e)
			}
		}

		const handlePaste = e => {
			if (type === 'tel') {
				onPhonePaste(e)
			}
		}

		return (
			<label className={cn(styles.field, className)} style={style}>
				{Icon && (
					<div className={styles.icon}>
						<Icon />
					</div>
				)}
				<input
					type={type}
					className={full}
					ref={ref}
					value={value}
					{...rest}
					onKeyDown={handleKeyDown} // Обработчик события нажатия клавиши
					onInput={handleInput} // Обработчик события ввода
					onPaste={handlePaste} // Обработчик события вставки
				/>
				{error && (
					<div
						className={isLogin ? 'none' : styles.error}
						style={{
							color: type === 'password' && passwordLength >= 8 ? 'green' : '#DC2626',
							marginLeft: 'auto',
						}}>
						{error.message}
						{type === 'password' && <div>{passwordLength + ' / 8'}</div>}
					</div>
				)}
			</label>
		)
	},
)

export default Field
