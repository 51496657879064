export const MAIN_ROUTE = '/'
export const ADMIN_ROUTE = '/admin'
export const ADMIN_PANEL_ROUTE = '/admin/panel'
export const BASKET_ROUTE = '/basket'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SHOP_ROUTE = '/shop'
export const DEVICE_ROUTE = '/device'
export const CREATE_AN_AD = '/createanad'
export const USER_PROFILE_ADS = '/user-ads'
export const CHAT_ROUTE = '/room'
