import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DEVICE_ROUTE } from '../../../utils/consts'

const DeviceItem = ({ device }) => {
	const truncatedDescription =
		device.description.length > 300 ? `${device.description.slice(0, 300)}...` : device.description
	const history = useNavigate()
	return (
		<div className='w-full'>
			<div
				style={{ cursor: 'pointer' }}
				onClick={() => history(DEVICE_ROUTE + '/' + device.id)}
				className='tr flex gap-x-6 bg-transparent xl:hover:bg-grey rounded-xl sm:p-6 mt-3 flex-col sm:flex-row gap-6'>
				<div
					className='bg-black rounded-xl w-full xl:w-96 h-60 object-cover object-center relative bg-center bg-cover'
					// style={{ backgroundImage: `url(${device.image})` }}></div>
					style={{
						backgroundImage: `url(${device.image || '/images/ajshdbjhb.jpg'})`,
					}}></div>
				<div className='w-full justify-between flex flex-col-reverse xl:flex-row'>
					<div>
						<p className='m-text mt-3 sm:mt-0'>{truncatedDescription}</p>
					</div>
					<div className='text-right'>
						<div className='sm:pl-3'>
							<h3 className='text-xl flex items-center gap-x-2'>
								Цена: <span>{device.price}</span>
							</h3>
							<h3 className='text-xl flex items-center gap-x-2 mt-3 sm:mt-0'>
								Состояние: <span>{device.condition === 'new' ? 'Новая' : 'Б/у'}</span>
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div className='border-b border-gray-900\/10 mt-3'></div>
		</div>
	)
}

export default DeviceItem
