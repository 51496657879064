import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
const RelatedProducts = () => {
	const [relatedProducts, setRelatedProducts] = useState([
		{ id: 1, name: 'Related Product 1', price: 2000, image: '/images/ajshdbjhb.jpg' },
		{ id: 2, name: 'Related Product 2', price: 2500, image: '/images/ajshdbjhb.jpg' },
		{ id: 3, name: 'Related Product 2', price: 2500, image: '/images/ajshdbjhb.jpg' },
		{ id: 4, name: 'Related Product 2', price: 2500, image: '/images/ajshdbjhb.jpg' },
	])

	return (
		<div className='mt-24 mb-12'>
			<h2 className='title-l mb-6'>Может вам понравится</h2>
			<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
				{relatedProducts.map(product => (
					<NavLink key={product.id} to={`/device/${product.id}`} className='group'>
						<div className='bg-grey p-4 rounded-xl'>
							<img
								src={product.image}
								alt={product.name}
								className='w-full h-40 object-cover mb-4 rounded-lg'
							/>
							<h3 className='text-lg font-semibold mb-2'>{product.name}</h3>
							<p className='text-gray-700'>${product.price}</p>
							<button className='mt-6 bg-accent tr px-4 py-2 rounded-xl group-hover:opacity-80'>
								Подробнее
							</button>
						</div>
					</NavLink>
				))}
			</div>
		</div>
	)
}

export default RelatedProducts
