import React from 'react'
import Chat from '../../components/screens/chat/Chat'

const ChatForm = () => {
	return (
		<div className='container'>
			<Chat />
		</div>
	)
}

export default ChatForm