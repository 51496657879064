import React, { useState } from 'react'
import CreateBrand from '../../modals/CreateBrand'
import CreateDevice from '../../modals/CreateDevice'
import CreateType from '../../modals/CreateType'
import styles from './AdminPage.module.scss'

const AdminPage = () => {
	const [deviceVisible, setDeviceVisible] = useState(false)
	const [typeVisible, setTypeVisible] = useState(false)
	const [brandVisible, setBrandVisible] = useState(false)

	return (
		<div className='container'>
			<div className={`${styles.wflex} flex flex-col gap-3 mt-6`}>
				<button onClick={() => setTypeVisible(true)} className='button'>
					Добавить тип
				</button>
				<button onClick={() => setBrandVisible(true)} className='button'>
					Добавить бренд
				</button>
				<button onClick={() => setDeviceVisible(true)} className='button'>
					Добавить устройство
				</button>
			</div>

			<CreateDevice show={deviceVisible} onHide={() => setDeviceVisible(false)} />
			<CreateType show={typeVisible} onHide={() => setTypeVisible(false)} />
			<CreateBrand show={brandVisible} onHide={() => setBrandVisible(false)} />
		</div>
	)
}

export default AdminPage
