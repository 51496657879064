// DeviceListUtils.js
import { fetchDevises } from '../../../http/deviceAPI'

export async function searchProducts(search, device) {
	let params = {}
	if (search.generation.length > 1) {
		params.car_gen_id = search.generation
	}
	if (search.type.length > 1) {
		params.detail_type_id = search.type
	}
	if (search.minPriceFilter) {
		params.min_price = search.minPriceFilter
	}
	if (search.maxPriceFilter) {
		params.max_price = search.maxPriceFilter
	}
	if (search.cityFilter) {
		params.city = search.cityFilter
	}
	if (search.conditionFilter) {
		let condition = findItemById(device.conditions, search.conditionFilter)
		if (condition.value !== '') {
			params.condition = condition.value
		}
	}
	if (search.sortFilter) {
		let condition = findItemById(device.sortings, search.sortFilter)
		if (condition.value !== '') {
			params.sort_by = condition.value
		}
	}
	if (search.descFilter) {
		let condition = findItemById(device.sortingsPrice, search.descFilter)
		if (condition.value !== '') {
			params.desc = condition.value
		}
	}

	const data = await fetchDevises(params)
	device.setDevice(data)
}

export function findItemById(items, value) {
	if (typeof value === 'number') {
		return items?.find(item => item.id === value)
	} else {
		const foundItem = items?.find(item => item.value === value)
		return foundItem?.id
	}
}

export async function resetSearchProducts(search, device) {
	search.setStamp('')
	search.setGeneration('')
	search.setModel('')
	search.setType('')
	search.setCategory('')

	search.setMinPriceFilter('')
	search.setMaxPriceFilter('')
	search.setConditionFilter('')
	search.setSortFilter('')
	search.setCityFilter('')
	search.setDescFilter('')

	search.plusKeyCounter()

	device.setBrands([])
	device.setGenerations([])
	device.setCategoriesTypes([])

	const params = {}

	const data = await fetchDevises(params)
	device.setDevice(data)
}
