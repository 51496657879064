import { makeAutoObservable } from 'mobx'

export default class DeviceStore {
	constructor() {
		this._types = []
		this._brands = []

		this._generations = []

		this._categories = []
		this._categoriesTypes = []
		this._conditions = [
			{ id: 1, name: 'Все состояния', value: '' },
			{ id: 2, name: 'Новые', value: 'new' },
			{ id: 3, name: 'Б/У', value: 'used' },
		]
		this._sortings = [
			{ id: 1, name: 'Вся сортировка', value: '' },
			{ id: 2, name: 'Время публикации', value: 'time' },
			{ id: 3, name: 'Цена', value: 'price' },
			{ id: 4, name: 'Популярность', value: 'score' },
		]
		this._sortingsPrice = [
			{ id: 1, name: 'Не учитывать сортировку по цене', value: '' },
			{ id: 2, name: 'Возрастанию', value: false },
			{ id: 3, name: 'Убыванию', value: true },
		]
		this._devices = []
		this._selectedType = {}
		this._selectedBrand = {}
		makeAutoObservable(this)
	}

	setTypes(types) {
		this._types = types
	}

	setBrands(brands) {
		this._brands = brands
	}

	setGenerations(generations) {
		this._generations = generations
	}

	setSortings(sortings) {
		this._sortings = sortings
	}

	setSortingsPrice(sortingsPrice) {
		this._sortingsPrice = sortingsPrice
	}

	setCategories(categories) {
		this._categories = categories
	}

	setDevice(devices) {
		this._devices = devices
	}

	setCondition(conditions) {
		this._conditions = conditions
	}

	setCategoriesTypes(types) {
		this._categoriesTypes = types
	}

	setSelectedType(type) {
		this._selectedType = type
	}

	setSelectedBrand(brand) {
		this._selectedBrand = brand
	}

	get types() {
		return this._types
	}

	get conditions() {
		return this._conditions
	}

	get sortings() {
		return this._sortings
	}

	get sortingsPrice() {
		return this._sortingsPrice
	}

	get brands() {
		return this._brands
	}

	get devices() {
		return this._devices
	}

	get generations() {
		return this._generations
	}

	get categories() {
		return this._categories
	}

	get categoriesTypes() {
		return this._categoriesTypes
	}

	get selectedType() {
		return this._selectedType
	}

	get selectedBrand() {
		return this._selectedBrand
	}
}
