import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './BuyAPart.module.scss'

const BuyAPart = ({ icon, headerTitle, underTitle, pieces, tires, link }) => {
	return (
		<NavLink className={`w-full ${styles.wrap__linker}`} to={link}>
			<div className={[styles.wrap, styles.grey].join(' ')}>
				<div className={styles.wrap__up}>
					<div className={styles.wrap__up__icon__wrap}>{icon}</div>
					<div className={`${styles.wrap__up__link} button`}>{headerTitle}</div>
				</div>
				<div className={`${styles.wrap__down} flex`}>
					{underTitle}
					{/* <div className={styles.arrow}>
					<ArrowUpRight />
				</div> */}
				</div>
				<div className={`${styles.wrap__down__under} flex m-text`}>
					{pieces} | {tires}
				</div>
			</div>
		</NavLink>
	)
}

export default BuyAPart
