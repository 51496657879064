import React, { useState } from 'react'

const RenderBreadcrumbs = () => {
	const [breadcrumbs, setBreadcrumbs] = useState([
		{ label: 'Главная', link: '/' },
		{ label: 'Каталог', link: '/shop' },
		{ label: 'Дверь задняя боковая левая Ниссан...', link: '/' },
	])

	return (
		<div>
			{breadcrumbs.map((item, index) => (
				<React.Fragment key={index}>
					{index > 0 && <span className='mx-2'>/</span>}
					{index === breadcrumbs.length - 1 ? (
						<span className='font-semibold text-accent'>{item.label}</span>
					) : (
						<a href={item.link || '#'} className='tr hover:text-accent'>
							{item.label}
						</a>
					)}
				</React.Fragment>
			))}
		</div>
	)
}

export default RenderBreadcrumbs
