export const getInputNumbersValue = inputValue => {
	return inputValue.replace(/\D/g, '')
}

export const onPhonePaste = e => {
	const input = e.target
	const inputNumbersValue = getInputNumbersValue(input.value)
	const pasted = e.clipboardData || window.clipboardData
	if (pasted) {
		const pastedText = pasted.getData('Text')
		if (/\D/g.test(pastedText)) {
			input.value = inputNumbersValue
			return
		}
	}
}

export const onPhoneInput = e => {
	const input = e.target
	let inputNumbersValue = getInputNumbersValue(input.value)
	const selectionStart = input.selectionStart
	let formattedInputValue = ''

	if (!inputNumbersValue) {
		return (input.value = '')
	}

	if (input.value.length !== selectionStart) {
		if (e.data && /\D/g.test(e.data)) {
			input.value = inputNumbersValue
		}
		return
	}

	if (['0', '1','2','3','4','5','6','7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
		if (inputNumbersValue[0] === '9') inputNumbersValue = '7' + inputNumbersValue
		const firstSymbols = inputNumbersValue[0] === '8' ? '+7' : '+7'
		formattedInputValue = input.value = firstSymbols + ' '
		if (inputNumbersValue.length > 1) {
			formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
		}
		if (inputNumbersValue.length >= 5) {
			formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
		}
		if (inputNumbersValue.length >= 8) {
			formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
		}
		if (inputNumbersValue.length >= 10) {
			formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
		}
	} else {
		formattedInputValue = '+' + inputNumbersValue.substring(0, 16)
	}
	input.value = formattedInputValue
}

export const onPhoneKeyDown = e => {
	const inputValue = e.target.value.replace(/\D/g, '')
	if (e.keyCode === 8 && inputValue.length === 1) {
		e.target.value = ''
	}
}
