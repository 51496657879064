import { PhotoIcon } from '@heroicons/react/24/solid'
import React from 'react'

const ShipForm = () => {
	return (
		<div className='pb-12'>
			<form>
				<div className='space-y-12 mt-12'>
					<div className='border-b border-gray-900/10 pb-12'>
						<h2 className='title'>Подать объявление о продаже катера</h2>

						<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6'>
							<div className='sm:col-span-4'>
								<label htmlFor='username' className='block text-ml leading-6 text-gray-900'>
									VIN или номер кузова
								</label>
								<div className='mt-2'>
									<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
										<input
											type='text'
											name='username'
											id='username'
											autoComplete='username'
											className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
											placeholder='VIN/номер кузова'
										/>
									</div>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label htmlFor='username' className='block text-ml leading-6 text-gray-900'>
									Номер СТС
								</label>
								<div className='mt-2'>
									<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
										<input
											type='text'
											name='username'
											id='username'
											autoComplete='username'
											className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
											placeholder='№1232'
										/>
									</div>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label htmlFor='username' className='block text-ml leading-6 text-gray-900'>
									Госномер
								</label>
								<div className='mt-2'>
									<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
										<input
											type='text'
											name='username'
											id='username'
											autoComplete='username'
											className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
											placeholder=''
										/>
									</div>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label htmlFor='username' className='block text-ml leading-6 text-gray-900'>
									Марка
								</label>
								<div className='mt-2'>
									<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
										<input
											type='text'
											name='username'
											id='username'
											autoComplete='username'
											className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
											placeholder=''
										/>
									</div>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label htmlFor='username' className='block text-ml leading-6 text-gray-900'>
									Модель
								</label>
								<div className='mt-2'>
									<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
										<input
											type='text'
											name='username'
											id='username'
											autoComplete='username'
											className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
											placeholder=''
										/>
									</div>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label htmlFor='username' className='block text-ml leading-6 text-gray-900'>
									Пробег
								</label>
								<div className='mt-2'>
									<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
										<input
											type='text'
											name='username'
											id='username'
											autoComplete='username'
											className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
											placeholder=''
										/>
									</div>
								</div>
							</div>

							<div className='col-span-full'>
								<label htmlFor='cover-photo' className='block text-ml leading-6 text-gray-900'>
									Cover photo
								</label>
								<div className='mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'>
									<div className='text-center'>
										<PhotoIcon className='mx-auto h-12 w-12 text-gray-300' aria-hidden='true' />
										<div className='mt-4 flex text-sm leading-6 text-gray-600'>
											<label
												htmlFor='file-upload'
												className='relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500'>
												<span>Upload a file</span>
												<input
													id='file-upload'
													name='file-upload'
													type='file'
													className='sr-only'
												/>
											</label>
											<p className='pl-1'>or drag and drop</p>
										</div>
										<p className='text-xs leading-5 text-gray-600'>PNG, JPG, GIF up to 10MB</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='border-b border-gray-900/10 pb-12'>
						<h2 className='text-base font-semibold leading-7 text-gray-900'>
							Personal Information
						</h2>
						<p className='mt-1 text-sm leading-6 text-gray-600'>
							Use a permanent address where you can receive mail.
						</p>

						<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
							<div className='sm:col-span-3'>
								<label htmlFor='first-name' className='block text-ml leading-6 text-gray-900'>
									First name
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='first-name'
										id='first-name'
										autoComplete='given-name'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>

							<div className='sm:col-span-3'>
								<label htmlFor='last-name' className='block text-ml leading-6 text-gray-900'>
									Last name
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='last-name'
										id='last-name'
										autoComplete='family-name'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>

							<div className='sm:col-span-4'>
								<label htmlFor='email' className='block text-ml leading-6 text-gray-900'>
									Email address
								</label>
								<div className='mt-2'>
									<input
										id='email'
										name='email'
										type='email'
										autoComplete='email'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>

							<div className='sm:col-span-3'>
								<label htmlFor='country' className='block text-ml leading-6 text-gray-900'>
									Country
								</label>
								<div className='mt-2'>
									<select
										id='country'
										name='country'
										autoComplete='country-name'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'>
										<option>United States</option>
										<option>Canada</option>
										<option>Mexico</option>
									</select>
								</div>
							</div>

							<div className='col-span-full'>
								<label htmlFor='street-address' className='block text-ml leading-6 text-gray-900'>
									Street address
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='street-address'
										id='street-address'
										autoComplete='street-address'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>

							<div className='sm:col-span-2 sm:col-start-1'>
								<label htmlFor='city' className='block text-ml leading-6 text-gray-900'>
									City
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='city'
										id='city'
										autoComplete='address-level2'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>

							<div className='sm:col-span-2'>
								<label htmlFor='region' className='block text-ml leading-6 text-gray-900'>
									State / Province
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='region'
										id='region'
										autoComplete='address-level1'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>

							<div className='sm:col-span-2'>
								<label htmlFor='postal-code' className='block text-ml leading-6 text-gray-900'>
									ZIP / Postal code
								</label>
								<div className='mt-2'>
									<input
										type='text'
										name='postal-code'
										id='postal-code'
										autoComplete='postal-code'
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='border-b border-gray-900/10 pb-12'>
						<h2 className='text-base font-semibold leading-7 text-gray-900'>Notifications</h2>
						<p className='mt-1 text-sm leading-6 text-gray-600'>
							We'll always let you know about important changes, but you pick what else you want to
							hear about.
						</p>

						<div className='mt-10 space-y-10'>
							<fieldset>
								<legend className='text-sm font-semibold leading-6 text-gray-900'>By Email</legend>
								<div className='mt-6 space-y-6'>
									<div className='relative flex gap-x-3'>
										<div className='flex h-6 items-center'>
											<input
												id='comments'
												name='comments'
												type='checkbox'
												className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
											/>
										</div>
										<div className='text-sm leading-6'>
											<label htmlFor='comments' className=' text-gray-900'>
												Comments
											</label>
											<p className='text-gray-500'>
												Get notified when someones posts a comment on a posting.
											</p>
										</div>
									</div>
									<div className='relative flex gap-x-3'>
										<div className='flex h-6 items-center'>
											<input
												id='candidates'
												name='candidates'
												type='checkbox'
												className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
											/>
										</div>
										<div className='text-sm leading-6'>
											<label htmlFor='candidates' className=' text-gray-900'>
												Candidates
											</label>
											<p className='text-gray-500'>
												Get notified when a candidate applies for a job.
											</p>
										</div>
									</div>
									<div className='relative flex gap-x-3'>
										<div className='flex h-6 items-center'>
											<input
												id='offers'
												name='offers'
												type='checkbox'
												className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
											/>
										</div>
										<div className='text-sm leading-6'>
											<label htmlFor='offers' className=' text-gray-900'>
												Offers
											</label>
											<p className='text-gray-500'>
												Get notified when a candidate accepts or rejects an offer.
											</p>
										</div>
									</div>
								</div>
							</fieldset>
							<fieldset>
								<legend className='text-sm font-semibold leading-6 text-gray-900'>
									Push Notifications
								</legend>
								<p className='mt-1 text-sm leading-6 text-gray-600'>
									These are delivered via SMS to your mobile phone.
								</p>
								<div className='mt-6 space-y-6'>
									<div className='flex items-center gap-x-3'>
										<input
											id='push-everything'
											name='push-notifications'
											type='radio'
											className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
										/>
										<label
											htmlFor='push-everything'
											className='block text-ml leading-6 text-gray-900'>
											Everything
										</label>
									</div>
									<div className='flex items-center gap-x-3'>
										<input
											id='push-email'
											name='push-notifications'
											type='radio'
											className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
										/>
										<label htmlFor='push-email' className='block text-ml leading-6 text-gray-900'>
											Same as email
										</label>
									</div>
									<div className='flex items-center gap-x-3'>
										<input
											id='push-nothing'
											name='push-notifications'
											type='radio'
											className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
										/>
										<label htmlFor='push-nothing' className='block text-ml leading-6 text-gray-900'>
											No push notifications
										</label>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</div>

				<div className='mt-6 flex items-center justify-end gap-x-6'>
					<button type='button' className='button'>
						Отменить
					</button>
					<button type='submit' className='button button-accent'>
						Сохранить
					</button>
				</div>
			</form>
		</div>
	)
}

export default ShipForm
