import { makeAutoObservable } from 'mobx'

export default class UserStore {
	constructor() {
		this._isAuth = false
		this._user = {}
		this._userProducts = []
		makeAutoObservable(this)
	}

	setIsAuth(bool) {
		this._isAuth = bool
	}

	setUserProducts(userProducts) {
		this._userProducts = userProducts
	}

	setUser(user) {
		this._user = user
	}

	get isAuth() {
		return this._isAuth
	}

	get userProducts() {
		return this._userProducts
	}

	get user() {
		return this._user
	}
}
