import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import { Fragment, useEffect, useState } from 'react'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const Dropdown = observer(
	({
		items = [],
		className,
		name = '',
		fullWidth = false,
		isPageShop = false,
		type = null,
		onChangeCallback,
		selectedId = null,
	}) => {
		const [optionsTitle, setOptionsTitle] = useState(name)

		useEffect(() => {
			const selectedOption = items.find(item => item.id === selectedId)
			if (selectedOption) {
				setOptionsTitle(selectedOption.name || selectedOption.brand_name)
			}
		}, [selectedId, items])

		function handleOnChange(typeName, value = '') {
			setOptionsTitle(typeName)
			if (onChangeCallback) {
				onChangeCallback(value)
			}
		}

		if (type) {
			return (
				<Menu as='div' className={`relative inline-block text-left ${className}`}>
					<Menu.Button className='flex outline-none p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between'>
						{optionsTitle}
						<ChevronDownIcon className='-mr-1 h-5 w-5' aria-hidden='true' />
					</Menu.Button>

					<Transition
						as={Fragment}
						enter='transition ease-out duration-100'
						enterFrom='transform opacity-0 scale-95'
						enterTo='transform opacity-100 scale-100'
						leave='transition ease-in duration-75'
						leaveFrom='transform opacity-100 scale-100'
						leaveTo='transform opacity-0 scale-95'>
						<Menu.Items
							className={`absolute left-0 z-10 mt-2 origin-top-right color-[#000] rounded-md bg-grey shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
								fullWidth ? 'w-full' : ''
							}`}>
							<div className='py-1'>
								{items?.map(type => (
									<Menu.Item key={type.id}>
										{({ active }) => (
											<div
												onClick={() =>
													handleOnChange(type.name || type.brand_name, type.id ? type.id : '')
												}
												className={classNames(
													active ? 'bg-gray-100 text-accent' : 'text-[#000]',
													'block px-4 py-2 text-sm cursor-pointer',
												)}>
												{type.name || type.brand_name}
											</div>
										)}
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			)
		}

		return (
			<Menu as='div' className={`relative inline-block text-left ${className}`}>
				{isPageShop ? (
					<div>
						<Menu.Button className='button flex items-center justify-between xl:justify-start gap-x-2 w-full items-center'>
							{optionsTitle}
							<ChevronDownIcon className='-mr-1 h-5 w-5' aria-hidden='true' />
						</Menu.Button>
					</div>
				) : (
					<div>
						<Menu.Button className='inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-grey px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
							{optionsTitle}
							<ChevronDownIcon className='-mr-1 h-5 w-5' aria-hidden='true' />
						</Menu.Button>
					</div>
				)}

				<Transition
					as={Fragment}
					enter='transition ease-out duration-100'
					enterFrom='transform opacity-0 scale-95'
					enterTo='transform opacity-100 scale-100'
					leave='transition ease-in duration-75'
					leaveFrom='transform opacity-100 scale-100'
					leaveTo='transform opacity-0 scale-95'>
					<Menu.Items
						className={`absolute left-0 z-10 mt-2 origin-top-right color-[#000] rounded-md bg-grey shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
							fullWidth ? 'w-full' : ''
						}`}>
						<div className='py-1'>
							{items?.map(type => (
								<Menu.Item key={type.id}>
									{({ active }) => (
										<div
											onClick={() =>
												handleOnChange(type.name || type.brand_name, type.id ? type.id : '')
											}
											href='#'
											className={classNames(
												active ? 'bg-gray-100 text-accent' : 'text-[#000]',
												'block px-4 py-2 text-sm cursor-pointer',
											)}>
											{type.name || type.brand_name}
										</div>
									)}
								</Menu.Item>
							))}
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		)
	},
)
export default Dropdown
