import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import { fetchAllCategories, fetchCarsBrands } from '../../../../http/carAndDetailSelectorAPI'
import { check, handleCreateProduct } from '../../../../http/userAPI'
import FileUpload from '../../../FileUpload/FileUpload'
import Dropdown from '../../../ui/Dropdown/Dropdowns'
import {
	setBrands,
	setCategories,
	setCategoriesTypes,
	setGeneration,
	setMarks,
} from '../../../ui/Navbar/NavbarSelected/NavbarSelectedUtils'
import { findItemById, resetSearchProducts } from '../../../ui/ShopDeviceList/SearchUtils'
import AccessAlert from '../../../ui/modal/AccessAlert/AccessAlert'
import ErrorAlert from '../../../ui/modal/ErrorAlert/ErrorAlert'

const CogForm = observer(() => {
	const { device, search, user } = useContext(Context)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [accessAlertShow, setAccessAlertShow] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')
	const [image, setImage] = useState(null)

	useEffect(() => {
		fetchCarsBrands().then(data => device.setTypes(data))
		fetchAllCategories().then(data => device.setCategories(data))
		resetSearchProducts(search, device)

		if (!user.isAuth) {
			check().then(data => {
				if (!data || !data?.status === 200) {
					setErrorStatus('Unauthorized')
					setAlertTitle('Вы сможете создать объявление только после авторизации')
					setErrorAlertShow(true)
				}
			})
		}
	}, [device, search, user])

	const [price, setPrice] = useState('')
	const [address, setAddress] = useState('')
	const [description, setDescription] = useState('')
	const [title, setTitle] = useState('')

	async function createProduct(e, price, address, title, description, genId, type, image) {
		e.preventDefault()
		try {
			let condition = findItemById(device?.conditions, search.conditionFilter)
			await handleCreateProduct(price, address, title, description, condition?.value, genId, type, image)
			setAlertTitle('Вы успешно создали товар!')
			setAccessAlertShow(true)
		} catch (error) {
			setAlertMessage(error.response)
			setErrorStatus(error.response?.status)
			setAlertTitle('Ошибка создания объявления ')
			setErrorAlertShow(true)
		}
	}

	function resetFormProducts(device, search) {
		setPrice('')
		setAddress('')
		setDescription('')
		setTitle('')
		setImage(null)
		resetSearchProducts(device, search)
	}

	return (
		<div className='pb-12'>
			<form>
				<div className='space-y-12 text-center'>
					<div className='pb-12 border-b border-gray-900\/10'>
						<h2 className='title'>Подать объявление о продаже запчасти</h2>

						<div className='flex justify-between relative mt-12 flex-col-reverse xl:flex-row'>
							<div className='xl:w-1/2 xl:pr-3'>
								<input
									className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
									placeholder='Цена'
									value={price}
									type='price'
									onChange={e => {
										let inputValue = e.target.value
										let onlyDigits = inputValue.replace(/\D/g, '')
										setPrice(onlyDigits)
									}}
								/>

								<Dropdown
									key={search.keyCounter}
									items={device.conditions}
									name={'Состояние'}
									className='w-full'
									fullWidth={true}
									isPageShop={true}
									type={true}
									onChangeCallback={selectedTypeValue => {
										search.setConditionFilter(selectedTypeValue)
									}}
								/>

								<textarea
									className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
									placeholder='Описание'
									value={description}
									onChange={e => setDescription(e.target.value)}
								/>

								<input
									className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
									placeholder='Адрес'
									onChange={e => setAddress(e.target.value)}
									value={address}
								/>

								<input
									className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
									placeholder='Название'
									onChange={e => setTitle(e.target.value)}
									value={title}
								/>

								<Dropdown
									key={`generationMark-${search.keyCounter}`}
									items={device.types}
									name={'Марка'}
									className={`w-full ${
										device.types?.length > 0 ? '' : 'pointer-events-none opacity-50'
									}`}
									fullWidth={true}
									isPageShop={true}
									type={true}
									onChangeCallback={selectedTypeValue => {
										setMarks(selectedTypeValue, device, search)
									}}
								/>
								<Dropdown
									key={`brandsDropdown-${search.stamp + search.keyCounter}`}
									items={device.brands}
									name={'Модель'}
									className={`w-full ${
										device.brands?.length > 0 ? '' : 'pointer-events-none opacity-50'
									}`}
									fullWidth={true}
									isPageShop={true}
									type={true}
									onChangeCallback={selectedTypeValue => {
										setBrands(selectedTypeValue, device, search)
									}}
								/>
								<Dropdown
									key={`generationDropdown-${search.stamp + search.model + search.keyCounter}`}
									items={device.generations}
									name={'Поколение'}
									className={`w-full ${
										device.generations?.length > 0 ? '' : 'pointer-events-none opacity-50'
									}`}
									fullWidth={true}
									isPageShop={true}
									type={true}
									onChangeCallback={selectedTypeValue => {
										setGeneration(selectedTypeValue, search, device)
									}}
								/>
								<Dropdown
									key={`generationCategories-${search.keyCounter}`}
									items={device.categories}
									name={'Категория'}
									className={`w-full ${
										device.categories?.length > 0 ? '' : 'pointer-events-none opacity-50'
									}`}
									fullWidth={true}
									isPageShop={true}
									type={true}
									onChangeCallback={selectedTypeValue => {
										setCategories(selectedTypeValue, search, device)
									}}
								/>
								<Dropdown
									key={`brandsCategoriesTypes-${search.category + search.keyCounter}`}
									items={device.categoriesTypes}
									name={'Тип'}
									className={`w-full ${
										device.categoriesTypes?.length > 0 ? '' : 'pointer-events-none opacity-50'
									}`}
									fullWidth={true}
									isPageShop={true}
									type={true}
									onChangeCallback={selectedTypeValue => {
										setCategoriesTypes(selectedTypeValue, search, device)
									}}
								/>
							</div>

							<div className='xl:w-1/2 xl:pl-3'>
								<FileUpload onFileSelect={file => setImage(file)} />
							</div>
						</div>
					</div>
				</div>

				<div className='mt-6 flex items-center justify-end gap-x-6'>
					<button
						type='button'
						className='button'
						onClick={() => resetFormProducts(search, device)}>
						Сбросить
					</button>
					<button
						className='button button-accent'
						onClick={e =>
							createProduct(e, price, address, title, description, search.generation, search.type, image)
						}>
						Отправить
					</button>
				</div>
			</form>

			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				message={alertMessage}
			/>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
			/>
		</div>
	)
})

export default CogForm
