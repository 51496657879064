// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminPage_wflex__vkaqO {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/screens/admin/AdminPage.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,iBAAA;EACA,kBAAA;AACD","sourcesContent":[".wflex {\n\tmax-width: 600px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wflex": `AdminPage_wflex__vkaqO`
};
export default ___CSS_LOADER_EXPORT___;
