import { Dialog, Transition } from '@headlessui/react'
import { AtSign, AtomIcon, Home, Phone } from 'lucide-react'
import { observer } from 'mobx-react-lite'
import { Fragment, useEffect, useRef, useState } from 'react'
import { check, handleUpdateProfile } from '../../http/userAPI'
import Field from '../ui/Field/Field'
import AccessAlert from '../ui/modal/AccessAlert/AccessAlert'
import ErrorAlert from '../ui/modal/ErrorAlert/ErrorAlert'

const ProfileUser = observer(({ show, onHide }) => {
	const cancelButtonRef = useRef(null)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [accessAlertShow, setAccessAlertShow] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [city, setCity] = useState('')
	const [phone, setPhone] = useState('')

	useEffect(() => {
		if (show) {
			check().then(data => {
				if (data) {
					setName(data?.data?.name)
					setEmail(data?.data?.email)
					setCity(data?.data?.city)
					setPhone(data?.data?.phone)
				}
			})
		}
	}, [show])

	async function updateProfile() {
		try {
			const data = await handleUpdateProfile(name, city, phone)
			console.log(data)
			setAlertTitle('Вы успешно обновили информацию')
			setAccessAlertShow(true)
		} catch (error) {
			setAlertMessage(error.response)
			setErrorStatus(error.response?.status)
			setAlertTitle('Ошибка ')
			setErrorAlertShow(true)
		}
	}

	return (
		<>
			<Transition.Root show={show} as={Fragment}>
				<Dialog
					as='div'
					className='relative z-10'
					initialFocus={cancelButtonRef}
					onClose={() => setAccessAlertShow(false)}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
						<div
							className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'
							onClick={() => onHide(false)}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-xl'>
									<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
										<div className='sm:flex sm:items-start'>
											<div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
												<Dialog.Title as='h3' className='text-lg font-bold leading-6 text-gray-900'>
													Мой профиль
												</Dialog.Title>
												<div className='mt-6'>
													<form className='w-full'>
														<div>
															<p className='text-gray-900 mb-2'>Ваше имя:</p>
															<Field
																required={true}
																placeholder='Ваше имя'
																className='mb-3 bg-grey p-3 w-full rounded-2xl'
																type='email'
																Icon={AtomIcon}
																value={name}
																onChange={e => setName(e.target.value)}
																full='w-full'
															/>
														</div>

														<div>
															<p className='text-gray-900 mb-2'>Ваша почта:</p>
															<label className='flex mb-3 bg-grey text-[#585654] p-3 w-full rounded-2xl point-event-none'>
																<div className='mr-3'>
																	<AtSign />
																</div>
																<div>{email}</div>
															</label>
														</div>

														<div>
															<p className='text-gray-900 mb-2'>Ваш телефон:</p>
															<Field
																required={true}
																placeholder='Ваш телефон'
																className='mb-3 bg-grey p-3 w-full rounded-2xl'
																type='email'
																Icon={Phone}
																value={phone}
																onChange={e => setPhone(e.target.value)}
																full='w-full'
															/>
														</div>

														<div>
															<p className='text-gray-900 mb-2'>Ваш город:</p>
															<Field
																required={true}
																placeholder='Ваш город'
																className='mb-3 bg-grey p-3 w-full rounded-2xl'
																type='email'
																Icon={Home}
																value={city}
																onChange={e => setCity(e.target.value)}
																full='w-full'
															/>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
										<button
											type='button'
											className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
											onClick={() => updateProfile()}>
											Обновить
										</button>
										<button
											type='button'
											className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
											onClick={() => onHide(false)}
											ref={cancelButtonRef}>
											Закрыть
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				message={alertMessage}
			/>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
			/>
		</>
	)
})

export default ProfileUser
