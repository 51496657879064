import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Fancybox from '../../components/ui/Fancybox/Fancybox'
import RelatedProducts from '../../components/ui/RelatedProducts/RelatedProducts'
import RenderBreadcrumbs from '../../components/ui/RenderBreadcrumbs/RenderBreadcrumbs'
import { fetchOneProduct } from '../../http/deviceAPI'

const DevicePage = observer(() => {
	const { id } = useParams()
	const [device, setDevice] = useState(null)

	useEffect(() => {
		if (id) {
			fetchOneProduct(id).then(data => {
				console.log(data)
				setDevice(data)
			})
		}
	}, [id])

	console.log(device)

	return (
		<div className='container'>
			<div className='flex flex-col-reverse gap-6 xl:gap-0 xl:flex-row gap-x-12'>
				<div className='xl:w-1/2'>
					<div className='h-50 xl:h-96 bg-grey p-6 rounded-2xl hidden xl:block'>
						<Fancybox
							className='w-full h-full'
							options={{
								Carousel: {
									infinite: false,
								},
							}}>
							<a data-fancybox='gallery' className='w-full h-full' href={device?.image}>
								<div
									className='w-full h-full bg-cover bg-center rounded-xl'
									style={{ backgroundImage: `url('${device?.image}')` }}></div>
							</a>
						</Fancybox>
					</div>
					<div className='flex justify-between xl:mt-12'>
						<div>
							<h3 className='text-xl flex items-center gap-x-2'>Описание</h3>
							<div className='m-text mt-3'>{device?.description}</div>
						</div>
					</div>
				</div>

				<div className='xl:w-1/2 flex flex-col xl:items-end xl:text-right'>
					<div className=''> {<RenderBreadcrumbs />}</div>

					<div className='h-96 bg-grey p-6 rounded-2xl w-full mt-6 block xl:hidden'>
						<Fancybox
							className='w-full h-full'
							options={{
								Carousel: {
									infinite: false,
								},
							}}>
							<a data-fancybox='gallery' className='w-full h-full' href={device?.img}>
								<div
									className='w-full h-full bg-cover bg-center rounded-xl'
									style={{ backgroundImage: `url('${device?.img}')` }}></div>
							</a>
						</Fancybox>
					</div>

					<h1 className='title-l mt-6'>{device?.name}</h1>
					<div className='flex flex-col gap-y-3 xl:text-right flex flex-col xl:items-end'>
						<h3 className='flex items-center gap-x-2 text-xl mt-6'>
							Цена: <span>{device?.price}руб.</span>
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							<a className='flex items-center gap-x-2' href={`tel: ${device?.phone}`}>
								Контакты: {device?.phone}
							</a>
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							Состояние детали:{' '}
							<span className='bg-accent py-1 px-3 block rounded-xl'>{device?.condition}</span>
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>Город: {device?.address}</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							Бренд: {device?.car_gen.name}
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							gen_name: {device?.car_gen.name}
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							model_name: {device?.car_gen.name}
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							category_name: {device?.detail_type.name}
						</h3>
						<h3 className='text-xl flex items-center gap-x-2'>
							type_name: {device?.detail_type.name}
						</h3>
					</div>
				</div>
			</div>
			<RelatedProducts />
		</div>
	)
})

export default DevicePage
