import { Bike, Car, Cog, Ship, Truck } from 'lucide-react'
import { makeAutoObservable } from 'mobx'
import BikeForm from '../components/createAnadPage/forms/BikeForm/BikeForm'
import CarFrom from '../components/createAnadPage/forms/CarForm/CarFrom'
import CogForm from '../components/createAnadPage/forms/CogForm/CogForm'
import ShipForm from '../components/createAnadPage/forms/ShipForm/ShipForm'
import TruckForm from '../components/createAnadPage/forms/TruckForm/TruckForm'

export default class CreateAnAdPage {
	constructor() {
		this._dataSections = [
			{
				id: 1,
				imageTitle: 'Легковые авто',
				image: <Car />,
				component: <CarFrom />,
			},
			{
				id: 2,
				imageTitle: 'Спецтехника',
				image: <Truck />,
				component: <TruckForm />,
			},
			{
				id: 3,
				imageTitle: 'Водная техника',
				image: <Ship />,
				component: <ShipForm />,
			},
			{
				id: 4,
				imageTitle: 'Мототехника',
				image: <Bike />,
				component: <BikeForm />,
			},
			{
				id: 5,
				imageTitle: 'Запчасти, шины, сервис',
				image: <Cog />,
				component: <CogForm />,
			},
		]

		this._activeDataSection = 5

		makeAutoObservable(this)
	}

	setDataSections(data) {
		this._dataSections = data
	}

	setActiveDataSection(id) {
		this._activeDataSection = id
	}

	get dataSections() {
		return this._dataSections
	}

	get activeDataSection() {
		return this._activeDataSection
	}
}
